@import '../../assets/styles/mixin';

section.lesson {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include bp-tablet-max {
    display: block;
  }

  div.content {
    width: 68.96%;

    @include bp-tablet-max {
      width: 100%;
    }

    &.w-full {
      width: 100%;
    }

    div.head {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 1.563rem;
      width: 100%;

      @include bp-tablet-max {
        display: block;
      }

      .back {
        border: none;
        background: none;
        outline: none;
        display: flex;
        align-items: center;
        @include trans;
        opacity: 1;

        &:hover {
          opacity: 0.75;
        }

        font-family: 'Poppins';
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1em;
        color: #181b1a;

        @include bp-tablet-max {
          font-size: 0.938rem;
          margin-bottom: 0.938rem;
        }

        .ico {
          width: 3.5rem;
          height: 3.5rem;
          @apply bg-orange;
          border-radius: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;

          @include bp-tablet-max {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 0.75rem;
            margin-right: 0.75rem;
          }

          svg {
            font-size: 1.563rem;
            color: #f8f8f8;

            @include bp-tablet-max {
              font-size: 1.125rem;
            }
          }
        }
      }

      .crumbs {
        &.has-btn-back {
          width: calc(100% - 10rem);

          @include bp-tablet-max {
            width: 100%;
            text-align: left;
          }
        }

        p {
          &,
          * {
            font-family: 'Poppins';
            display: inline;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.3em;
            color: #503e9d;
            margin-bottom: 0.375rem;

            @include bp-tablet-max {
              font-size: 1rem;
              line-height: 1.15em;
              margin-bottom: 0.25rem;
            }
          }

          span {
            &.title {
              font-weight: 500;
              font-size: 1.75rem;
              line-height: 1.5em;
              display: block;
              color: #181b1a;

              @include bp-tablet-max {
                font-size: 1rem;
                line-height: 1.15em;
              }
            }
          }
        }

        .title {
          width: auto;
        }
      }
    }

    .intro {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.3em;
      color: #181b1a;
      margin: -1.25rem 0 2.5rem;
      display: block;

      @include bp-tablet-max {
        font-size: 0.875rem;
        line-height: 1.15em;
        margin-bottom: 1.25rem;
      }
    }

    .course-module {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.3em;
      color: #181b1a;
      margin-bottom: 1.125rem;

      @include bp-tablet-max {
      }

      strong {
        font-weight: 700;
        font-size: 1.25rem;
        color: #503e9d;
        margin-bottom: 0.375rem;

        @include bp-tablet-max {
        }
      }
    }

    div.video-component {
      margin-bottom: 1.125rem;

      @include bp-tablet-max {
      }
    }

    div.content-data {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 0.063rem solid #f1f1ef;
      padding-bottom: 1.25rem;
      margin-bottom: 1.5rem;

      @include bp-tablet-max {
        padding-bottom: 0.938rem;
        margin-bottom: 1.188rem;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    .title {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 1.5em;
      color: #181b1a;
      display: block;
      margin: 0;
      width: calc(100% - 6.563rem);

      @include bp-tablet-max {
        font-size: 1.25rem;
        width: auto;
        margin-right: 0.938rem;
      }
    }

    .social {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0.5rem;

      @include bp-tablet-max {
        margin-top: 0;
      }

      > * {
        margin-right: 1rem;

        @include bp-tablet-max {
          margin-right: 0.75rem;
        }

        &:last-child {
          margin-right: 0;

          svg {
            margin-right: 0;
          }
        }
      }

      .like,
      .dislike {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1em;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        color: #dfdfe3;
        transform: scale(1);
        @include trans;
        outline: none;

        @include bp-tablet-max {
        }

        &:hover {
          color: #000;
        }

        svg {
          width: 1.375rem;
          height: auto;
          display: block;
          margin: 0;

          @include bp-tablet-max {
          }
        }

        span {
          margin-bottom: 0.063rem;
          margin-left: 0.313rem;
        }

        &.active {
          animation-duration: 0.45s;
          animation-name: like-button-animation;
          animation-timing-function: ease-in-out;
        }
      }

      .like {
        &.active,
        &:hover {
          color: #6fd181;
        }
      }

      .dislike {
        &.active,
        &:hover {
          color: #ed4956;
        }
      }
    }

    .description {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: #181b1a;

      @include bp-tablet-max {
        line-height: 1.25em;
      }
    }

    .download-file {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 1.875rem;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:hover {
          .title {
            color: #503e9d;
          }
        }

        .ico {
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 8.125rem;
          height: 8.125rem;
          position: relative;
          border-radius: 1rem;
          background: rgba(251, 109, 58, 0.075);
          margin-bottom: 0.938rem;

          svg {
            @apply text-orange;
            position: absolute;
            font-size: 4.375rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
          }
        }

        div {
          text-align: center;

          .title {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1em;
            color: #181b1a;
            display: block;
            margin: 0 0 0.313rem;
            @include trans;
            white-space: nowrap;
          }

          .meta {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.5em;
            color: #b3b6b6;
            white-space: nowrap;
          }
        }
      }
    }
  }

  div.right-data {
    width: 26%;

    @include bp-tablet-max {
      width: 100%;
    }
  }

  div.next-lessons {
    width: 100%;

    @include bp-tablet-max {
      border-top: 0.063rem solid #f1f1ef;
      padding-top: 1.875rem;
      margin-top: 1.875rem;
    }

    div.list {
      padding-top: 1.875rem;

      @include bp-tablet-max {
        padding: 0;
        width: 100%;
      }

      h4 {
        font-weight: 500;
        font-size: 1.625rem;
        line-height: 1.3em;
        color: #181b1a;
        display: block;
        margin-bottom: 1.625rem;

        @include bp-tablet-max {
          font-size: 1.125rem;
          margin-bottom: 1.25rem;
        }
      }

      ul {
        &,
        li {
          list-style: none;
          padding: 0;
          margin: 0;
        }
      }

      > div {
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        height: auto;
        max-height: 40.625rem;

        @include bp-tablet-max {
          max-height: none;
        }

        .modules {
          padding-right: 0.625rem;

          @include bp-tablet-max {
          }
        }

        .module-item {
          &.multiple {
            margin-bottom: 0.938rem;
            padding-bottom: 0.938rem;
            border-bottom: 0.063rem solid #f1f1ef;

            @include bp-tablet-max {
            }

            &:last-child {
              padding-bottom: 0;
              margin-bottom: 0;
              border-bottom: none;
            }
          }
        }

        .module-title {
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 1em;
          color: #503e9d;
          display: block;
          margin: 0 0 0.938rem;
          font-style: italic;

          @include bp-tablet-max {
          }
        }

        .lesson-item {
          margin-bottom: 0.938rem;
          padding-bottom: 0.938rem;
          position: relative;

          @include bp-tablet-max {
            margin-bottom: 0.75rem;
            padding-bottom: 0.75rem;
          }

          &:after {
            content: ' ';
            position: absolute;
            bottom: 0;
            width: calc(100% - 4.75rem);
            height: 0.063rem;
            display: block;
            right: 0;
            background: #f1f1ef;
            pointer-events: none;

            @include bp-tablet-max {
              width: calc(100% - 3.438rem);
            }
          }

          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;

            &:after {
              display: none;
            }
          }

          &.locked {
            > button {
              cursor: not-allowed;
            }
          }

          &.active {
            > button {
              cursor: default;
            }
          }

          &.inactive.unlocked {
            > button {
              &:hover {
                .meta {
                  h6 {
                    color: #503e9d;
                  }
                }
              }
            }
          }

          > button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            outline: none;

            @include bp-tablet-max {
            }

            // &:hover {
            //   .img {
            //     &.active {
            //       &:before {
            //         opacity: 0.5;
            //       }
            //     }
            //   }
            // }
          }

          .img {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.5rem;
            height: 3.5rem;
            position: relative;
            border-radius: 1rem;

            @include bp-tablet-max {
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 0.75rem;
            }

            &.locked,
            &.active {
              &:before {
                content: ' ';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                @include trans;
                opacity: 1;
              }

              img {
                display: none;
              }

              svg {
                color: #f8f8f8;
                position: absolute;
                font-size: 1.313rem;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 10;

                @include bp-tablet-max {
                  font-size: 1.125rem;
                }
              }
            }

            &.locked {
              &:before {
                background: #b3b6b6;
              }

              img {
                display: none;
              }
            }

            &.active {
              &:before {
                @apply bg-orange;
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .meta {
            text-align: left;
            width: calc(100% - 4.75rem);

            @include bp-tablet-max {
              width: calc(100% - 3.438rem);
            }

            h6 {
              font-weight: 500;
              font-size: 1rem;
              line-height: 1em;
              color: #181b1a;
              display: block;
              margin: 0 0 0.313rem;
              @include trans;

              @include bp-tablet-max {
                font-size: 0.938rem;
              }
            }

            p {
              font-weight: 500;
              font-size: 0.875rem;
              line-height: 1.5em;
              color: #b3b6b6;

              @include bp-tablet-max {
                font-size: 0.813rem;
              }
            }
          }
        }
      }
    }
  }

  .extra-content {
    width: 100%;
    margin-top: 3.75rem;

    h4 {
      font-weight: 500;
      font-size: 1.625rem;
      line-height: 1.3em;
      color: #181b1a;
      display: block;
      margin-bottom: 1.625rem;

      span {
        font: inherit;
        line-height: inherit;
        @apply text-orange;
      }
    }

    ul {
      li {
        margin-bottom: 0.938rem;
        padding-bottom: 0.938rem;
        position: relative;

        &:after {
          content: ' ';
          position: absolute;
          bottom: 0;
          width: calc(100% - 4.75rem);
          height: 0.063rem;
          display: block;
          right: 0;
          background: #f1f1ef;
          pointer-events: none;
        }

        &:last-child {
          margin: 0;
          padding: 0;

          &:after {
            display: none;
          }
        }

        button,
        a {
          border: none;
          background: none;
          margin: 0;
          padding: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
          width: 100%;
          text-decoration: none;
          outline: none;

          &:hover {
            .title {
              color: #503e9d;
            }
          }

          .ico {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.5rem;
            height: 3.5rem;
            position: relative;
            border-radius: 1rem;
            background: rgba(251, 109, 58, 0.075);

            svg {
              @apply text-orange;
              position: absolute;
              font-size: 1.875rem;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              z-index: 10;
            }
          }

          div {
            text-align: left;
            width: calc(100% - 4.75rem);

            .title {
              font-weight: 500;
              font-size: 1rem;
              line-height: 1em;
              color: #181b1a;
              display: block;
              margin: 0 0 0.313rem;
              @include trans;
            }

            .meta {
              font-weight: 500;
              font-size: 0.875rem;
              line-height: 1.5em;
              color: #b3b6b6;
            }
          }
        }
      }
    }
  }
}
