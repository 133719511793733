@import '../../assets/styles/mixin';

#auth {
  form {
    width: 100%;
    max-width: 15.625rem;
    margin: 0 auto;
    display: block;
    padding-top: 2.5rem;

    ul {
      &,
      li {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      li {
        margin-bottom: 0.938rem;

        &:last-child {
          margin-bottom: 0;
        }

        input {
          width: 100%;
          @apply px-4 py-2 text-sm leading-none border block border-gray-300 rounded;
          height: 2.5rem;
          background: #fff;

          &[aria-invalid='true'] {
            border-color: #ff7171;
          }

          @include trans;
          opacity: 1;

          &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        span[role='alert'] {
          font-style: italic;
          font-size: 0.75rem;
          color: #ff7171;
          display: block;
        }
      }
    }

    button[type='submit'] {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6.25rem;
      height: 2.5rem;
      background: transparent;
      border-radius: 100rem;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      border: 0.063rem solid #fff;
      margin: 1.875rem auto 0;
      @include trans;
      opacity: 1;

      &:hover {
        opacity: 0.75;
      }

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
