@import '../../assets/styles/mixin';

#settings {
  .pages {
    width: calc(100% + 1.875rem);
    margin-left: -0.938rem;
    margin-top: -0.938rem;

    @include bp-tablet-max {
      width: 100%;
      margin: 0;
    }

    .page {
      // width: calc(25% - 1.875rem);
      width: calc(33.33% - 1.875rem);
      margin: 0.938rem;

      @media only screen and (max-width: 1439px) {
        width: calc(33.33% - 1.875rem);
      }

      @include bp-tablet-max {
        width: 100%;
        margin: 0 0 0.938rem;
      }

      a {
        @include trans;
        opacity: 1;

        &:hover {
          opacity: 0.75;
        }
      }

      .img {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.438rem;
        height: 3.438rem;
        position: relative;
        background: rgba(80, 62, 157, 0.25);
        border-radius: 1rem;

        svg {
          color: #503e9d;
          position: absolute;
          font-size: 1.563rem;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
        }
      }

      .text {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1em;
        color: #181b1a;
        display: block;
        margin: 0;
        @include trans;
        width: calc(100% - 4.375rem);
      }
    }
  }
}
