@import '../../assets/styles/mixin';

#terms {
  .content {
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.938rem;
    line-height: 1.3em;

    @include bp-tablet-max {
      font-size: 0.813rem;
    }
  }
}
