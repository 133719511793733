@import '../../assets/styles/mixin';

.box-next-lessons {
  &__head {
    h2 {
      font-size: 1.75rem;
      line-height: 1.5em;
      color: #181b1a;

      @include bp-tablet-max {
        font-size: 1.25rem;
      }
    }
  }

  &__actions {
    button {
      color: #dfdfe3;
      font-size: 1.875rem;

      @include bp-tablet-max {
        font-size: 1.25rem;
      }
    }
  }
}
